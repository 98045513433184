<template>
    <div class="flex-between header">
        <div class="flex center">
            <img src="@/assets/logo.png" class="logo" alt="" />
            <div class="name">鼓楼医院排水智慧管理系统</div>
        </div>

        <div class="extra">
            <Date />
        </div>
    </div>
</template>
  
<script>
import Date from "./date.vue";
export default {
    components: {
        Date,
    },


};
</script>
  
<style lang="less" scoped>
.header {
    background-image: url(../../assets/layout/header-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    height: 12vh;
    padding-left: 1vw;

    .logo {
        margin-right: 0.8vw;
        height: 5vh;
    }

    .name {
        margin-top: -1vh;
        font-size: 4vh;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        letter-spacing: 2px;
        background: linear-gradient(180deg, #FFFFFF 0%, #699DDD 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;



    }
}
</style>
  