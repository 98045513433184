<template>
  <div class="flex center">
    <div class="date">
      <div>{{ day }}</div>
      <div>{{ date }}</div>
    </div>
    <div class="weather">
      <img src="#" alt="" />
      <span>17度</span>
    </div>
    <div class="user">
      <!-- <img src="@/assets/icon-user.png" alt="" /> -->
      <Avatar />
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
import Avatar from "./avatar.vue";
export default {
  components: {
    Avatar,
  },
  data() {
    return {
      day: "",
      date: "",
    };
  },
  mounted() {
    this.setDate();
  },
  methods: {
    setDate() {
      const now = new Date();
      switch (now.getDay()) {
        case 1:
          this.day = "周一";
          break;
        case 2:
          this.day = "周二";
          break;
        case 3:
          this.day = "周三";
          break;
        case 4:
          this.day = "周四";
          break;
        case 5:
          this.day = "周五";
          break;
        case 6:
          this.day = "周六";
          break;
        case 7:
          this.day = "周日";
          break;
      }
      this.date = `${now.getFullYear()}.${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}.${String(now.getDate()).padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="less" scoped>
.date {
  margin-top: 2.4vh;
  margin-left: 1vw;
  margin-right: 0.5vw;
  padding-right: 0.5vw;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.weather {
  margin-top: 2.4vh;
  font-size: 2.6vh;
}

.user {
  margin-top: 2.4vh;
  margin-left: 1vw;
  position: relative;

  display: flex;
  justify-content: center;
}
</style>
