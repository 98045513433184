<template>
  <div class="menu-box">
    <img class="bg" src="@/assets/layout/menu-bg.png" alt="">

    <div class="menu">
      <div class="menu-item" v-for="item in list" :key="item.id">
        <div class="title" @click="onMenuClick(item)">
          <img src="@/assets/menu-icon-active.png" v-if="current === item.id" />
          <img src="@/assets/menu-icon.png" v-else />
          <span>{{ item.title }}</span>
        </div>
        <div class="submenu" v-show="current === item.id">
          <div :class="$route.fullPath.includes(ele.path) ? 'active' : ''" v-for="ele in item.children" :key="ele.id"
            @click="onMenuItemClick(ele)">
            {{ ele.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import { buildMenuTree } from "@/views/setting/menu/tools.js";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: "",
      treeMenu: [],
    };
  },
  computed: {
    // ...mapState("auth", ["menuList"]),
    /* treeMenu(){
      return 
    } */
  },
  watch: {
    menuList() {
      let menus = [...menus];
      let list = Object.freeze(buildMenuTree(menus, "0"));
      console.log(list);
    },
    list() {
      this.current = window.localStorage.getItem("menuActive")
        ? window.localStorage.getItem("menuActive")
        : this.list[0]?.id;
    },
  },

  methods: {
    onMenuClick(item) {
      if (item.id === this.current) {
        this.current = "";
        return;
      }
      this.current = item.id;
      window.localStorage.setItem("menuActive", this.current);
    },
    onMenuItemClick(item) {
      console.log();
      if (item.path === this.$route.fullPath) {
        return;
      }
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="less" scoped>
.menu-box {
  position: relative;

  .bg {
    width: 20vw;
  }

  .menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 80vh;
    width: 18vw;
    padding: 3vw;
    overflow: hidden;
    background-size: cover;
  }

  .menu-item {
    margin-bottom: 10px;
    cursor: pointer;

    .title {
      border: 1px solid #fff;
      padding: 5px 10px;
      font-size: 1em;
      font-family: FZZCHJW--GB1-0, FZZCHJW--GB1;
      font-weight: normal;
      color: #00ffff;
      // line-height: 1.5em;

      background: linear-gradient(90deg,
          rgba(34, 134, 246, 0.16) 0%,
          rgba(34, 134, 246, 0) 100%);
      border: 1px solid;
      border-image: linear-gradient(90deg,
          rgba(34, 134, 246, 1),
          rgba(34, 134, 246, 0)) 1 1;
      display: flex;
      align-items: center;
      gap: 10px;
      border-right: none;

      img {
        width: 20px;
        height: 18px;
      }
    }

    .submenu {
      div {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c8eaff;
        font-size: 0.8em;
        height: 30px;
        line-height: 30px;
        margin-left: 50px;
      }

      .active {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 80px;
          height: 20px;
          background-image: url("../assets/layout/menu-underline-style.png");
          background-size: 100% 100%;
          left: -12px;
          top: 19px;
        }
      }
    }
  }
}
</style>
