<template>
  <div class="layout">

    <Header />

    <div class="body">
      <Menu :list="menus" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { fetchUserPermissionList } from "@/api/login";
import { mapActions, mapState, mapMutations } from "vuex";
import Header from './components/header.vue'
import Menu from "./Menu";
// import { fetchUserPermissionList } from "@/api/login";
export default {
  components: {
    Menu,
    Header,
  },
  data() {
    return {
      menus: [],
      day: "",
      date: "",
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async mounted() {
    const userStr = window.localStorage.getItem("user");
    let user = "";
    // 若为空，跳转至登陆界面
    if (!userStr) {
      this.$router.push("/login");
      return;
    }
    user = JSON.parse(userStr);

    const res = await fetchUserPermissionList({ id: user.id });
    console.log("per", res);
    if (res && Array.isArray(res.role)) {
      // 添加角色
      this.setUser({
        ...user,
        roles: res.role,
      });
    }

    // 获取用户的menu
    // await this.getMenu(res.permission);
    // await this.getDataDict();

    this.menus = [
      {
        title: "排水专题",
        id: "1",
        children: [
          {
            title: "院区总览",
            id: "2",
            path: "/drainage/overview",
          },
          {
            title: "防汛防涝",
            id: "31",
            path: "/drainage/preventFlood",
          },
          {
            title: "统计分析",
            id: "32",
            path: "/drainage/analysis",
          },
          {
            title: "应急管理",
            id: "321",
            path: "/drainage/emergancy",
          },
        ],
      },
      {
        title: "供水专题",
        id: "4",

        children: [
          {
            title: "供水管网",
            id: "5",
            path: "/water-supply/pipe",
          },
          {
            title: "供水监控",
            id: "61",
            path: "/water-supply/special/detail1",
          },
          {
            title: "供水调度",
            id: "62",
            path: "/waterSupply/special/detail2",
          },
          {
            title: "供水调度",
            id: "62",
            path: "/waterSupply/special/detail2",
          },
          {
            title: '渗透监测',
            id: '32323',
            path: '/water-supply/permeate'
          },
          {
            title: "用水分析",
            id: "63",
            path: "/water-supply/analysis",
          },
        ],
      },
      {
        title: "污水专题",
        id: "41",
        children: [
          {
            title: "污水站监测",
            id: "51",
            path: "/sewage/monitor",
          },
          {
            title: "智能分析",
            id: "61",
            path: "/sewage/analysis",
          },
        ],
      },
      {
        title: "综合管理",
        id: "411",
        children: [
          {
            title: "任务池",
            id: "512",
            path: "/riskTraining",
          },

          {
            title: "任务配置",
            id: "613",
            path: "/riskMaterial",
          },
        ],
      },
      {
        title: "系统设置",
        id: "3212",
        children: [
          {
            title: "预警方案管理",
            id: "513332",
            path: "/warning",
          },
          {
            title: "设备管理",
            id: "51333212",
            path: "/facility",
          },

          {
            title: "权限管理",
            id: "123",
            path: "/setting/permission",
          },
          {
            title: "菜单管理",
            id: "1234",
            path: "/setting/menu",
          },
          {
            title: "角色管理",
            id: "12345",
            path: "/setting/role",
          },
          {
            title: "用户管理",
            id: "123123",
            path: "/setting/user",
          },

          {
            title: "数据字典",
            id: "513321232",
            path: "/setting/data-dict",
          },

          {
            title: "系统日志",
            id: "5112312332",
            path: "/setting/log",
          },
        ],
      },
    ];
  },
  methods: {
    ...mapActions("setting", ["getDataDict"]),
    ...mapMutations("auth", ["setUser"]),
    ...mapActions("auth", ["getMenu"]),
    setActive(item) {
      if (item.path === "/") {
        return this.$route.path === item.path ? "active" : "";
      } else {
        return this.$route.path.indexOf(item.path) > -1 ? "active" : "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  min-height: 100vh;
  background-color: #162435;
  background-size: cover;
  background-position: center;


}

.body {
  flex: 1;
  display: flex;
  gap: 2vw;
  padding-right: 1vw;
}
</style>
