<template>
  <a-dropdown>
    <div class="header-avatar my-hover">
      <a-avatar v-if="user.header" size="small" shape="circle" :src="user.header" />
      <img class="avatar" src="@/assets/layout/icon-user.png" v-else />
      <span class="name">{{ user.userName }}</span>
    </div>
    <a-menu :class="['avatar-menu']" slot="overlay">
      <a-menu-item @click="goPage('/personal-setting')">
        <a-icon type="user" />
        <span>个人信息</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click="goPage('/personal-setting')">
        <a-icon type="setting" />
        <span>账号设置</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click="logout">
        <a-icon style="margin-right: 8px" type="poweroff" />
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  components: {
  },

  data() {
    return {
      user: {},
    };
  },

  mounted() {
    const userStr = window.localStorage.getItem("user");
    if (userStr) {
      this.user = JSON.parse(userStr);
    }
  },
  methods: {
    ...mapMutations("auth", ["setUser"]),
    goPage(path) {
      this.$router.push(path);
    },

    logout() {
      // 退出登录，清空数据缓存
      window.localStorage.clear();
      this.setUser({});
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.header-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .name {
    margin-left: 8px;
    font-weight: 500;
  }

  .avatar {
    height: 3em;
  }
}

.avatar-menu {
  width: 150px;
}
</style>
